.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo-img{
  /* resize: both; */
  /* height: 80px; */
  aspect-ratio: attr(width) / attr(height);
}
.navbar-brand {

  margin-right: 0rem !important;

}

div#spreedly-cvv  {
  padding: .175rem .75rem .500rem;
}
input, input:focus{
  outline:0px !important;
  /* -webkit-appearance:none; */
}

:focus {
  outline: 0px solid black !important;
}