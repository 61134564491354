//Main Pallete Colors
$primary         : #212529;
$btn-primary      :#3c78d8;
$btn-secondary    :#000000;
$btn-mail: #6d9eeb;
$colorwhite :    #ffffff;
$colorblack:     #000000;



@mixin none-selection {
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select   : none;
    /* Firefox 2+ */
    -ms-user-select    : none;
    /* IE 10+ */
    user-select        : none;
    /* Standard syntax */
}