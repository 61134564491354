@import "varibales.scss";
@import "~animate.css/animate.min.css";
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
    color: $colorblack;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-top: 0;
}
.checkout-form input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   font-size: 14px;
   font-weight: 400;
   font-family: "Arial";
   color: rgb(117, 117, 117);
   opacity: 0.6;
 }
// LOADING APP
.spinner-wrapper {
    position: absolute;
    top: 20%;
    width: 100%;
}
.spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #333;
  
    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
// LOADING APP
h5{
    margin-top: 10px;
    font-weight: 500;
    font-family: 'Poppins';
}
.pull-right {
    float: right;
}
.btn-custom {
    border: none;
    color: $colorwhite;
    display: block;
    width: 100%;
    letter-spacing: 1px;
    line-height: 1.2;
    padding: 18px 20px;
    text-align: center;
    z-index: 9;
    margin-bottom: 10px;
    font-size: 1rem;
    font-family: sans-serif;
}
.btn{
    background-color:$btn-primary !important;
    border: none;
    color: #ffffff !important;
    display: block;
    letter-spacing: 1px;
    line-height: 1.2;
    padding: 9px 20px;
    text-align: center;
    z-index: 9;
    margin-bottom: 10px;
    font-size: 14px;
}
.OtpTimerWrap {
    button {
        background-color: #3c78d8 !important;
        color: #ffffff !important;
        display: block;
        letter-spacing: 1px;
        padding: 5px 20px;
        text-align: center;
        z-index: 9;
        margin-bottom: 10px;
        border-radius: 3px;
        span {
            font-size: 14px;
            font-weight: normal;
            font-family: 'Poppins', sans-serif;
        }
    }
    span {
        font-size: 14px;
        font-weight: normal;
        font-family: 'Poppins', sans-serif;
    }
}
.DIVhide {
    display: none;
}
.show-hide-new-old-cart {
    margin-top: 20px;
    .btn-default {
        background-color: #b7b7b7;
        color: #000;
        font-weight: bold;
    }
}
.btn-Primary{
    background-color:$btn-primary !important;   
}
.pay-button {
   position: relative;
   transition: all 0.5s ease-in-out 0s;
   background: #a749ff !important;
   border: 1px solid black;
   color: #fff !important;
}
.pay-button.active {
    background-color: #95cc47 !important;
    color: #FFF;
}
.btn-Primary.disabled {
    cursor: auto;
    opacity: 0.8;
}
.snippet {
    position: relative;
}
.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 -5%;
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
    box-shadow: 9999px 0 0 -5px #000000;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
    margin-top: 15px;
}
.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #000000;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}
.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #000000;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}
.btn-facebook {
    background-color: $btn-primary ;
}
.btn-apple {
    background-color: $btn-secondary;
}

.btn-gmail, .btn-action {
    background-color: $btn-mail;
}
.small-text {
    font-size: 12px;
    letter-spacing: 0.5px;
    //padding-left: 15px;
}
.email-guest {
   margin-top: 4.2%;
}
.email-register {
   margin-top: 1%;
}
.checkout-form label {
    width: 100%;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5px;
}
small, .small {
   font-size: 1rem;
    font-weight: 400;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}
.text-mute {
    color: #CCC;
}

.float-right{
    float: right;
}
.login-mobile-input {
    position: relative;
    .fa-edit {
        position: absolute;
        right: 10px;
        top: 12px;
    }
}
.btn-font-20{
    font-weight: bold;
    color: #000;
    font-size: 20px;
}
p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #333;
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;
}
.line-separator {
    height: 2px !important;
    background-color: #000;
    opacity: 1;
    margin: 2px 0px;
}

.cart-container {
    position: relative;
    .cart-icon {
        display: none;
        background-repeat: no-repeat;
        background-size: auto 80%;
        height: 100%;
        height: 25px;
        text-align: center;
        width: 40px;
        padding: 17px 20px 35px 11px;
        position: absolute;
        top: -50px;
        right: -4px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDY0IDY0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTQ5LjU5LDE1LjgzaC02Ljk2di0xLjU3YzAtNS44Ni00Ljc3LTEwLjYzLTEwLjYzLTEwLjYzUzIxLjM3LDguMzksMjEuMzcsMTQuMjV2MS41N2gtNi45NmMtMC43NywwLTEuNDEsMC42LTEuNDYsMS4zNiAgTDEwLjAzLDU4LjhjLTAuMDMsMC40LDAuMTEsMC44MSwwLjM5LDEuMWMwLjI4LDAuMywwLjY3LDAuNDcsMS4wNywwLjQ3aDQxLjAyYzAuNDEsMCwwLjgtMC4xNywxLjA3LTAuNDcgIGMwLjI4LTAuMywwLjQyLTAuNywwLjM5LTEuMWwtMi45Mi00MS42MUM1MSwxNi40Myw1MC4zNiwxNS44Myw0OS41OSwxNS44M3ogTTIzLjEyLDE0LjI1YzAtNC45LDMuOTgtOC44OCw4Ljg4LTguODggIHM4Ljg4LDMuOTgsOC44OCw4Ljg4djEuNTdIMjMuMTJWMTQuMjV6IE0xMS44LDU4LjYybDIuODgtNDEuMDVoNi43djkuNTZjMCwwLjQ4LDAuMzksMC44OCwwLjg4LDAuODhzMC44OC0wLjM5LDAuODgtMC44OHYtOS41NiAgaDE3Ljc2djkuNTZjMCwwLjQ4LDAuMzksMC44OCwwLjg4LDAuODhzMC44OC0wLjM5LDAuODgtMC44OHYtOS41Nmg2LjdsMi44OCw0MS4wNUgxMS44eiI+PC9wYXRoPjwvc3ZnPg==);
    }
    .collapsed {
        .cart-icon {
            display: block;
        }
    }
    .cart-icon-2 {
        display: block;
        background-repeat: no-repeat;
        background-size: auto 80%;
        height: 100%;
        height: 25px;
        text-align: center;
        width: 40px;
        padding: 17px 20px 35px 11px;
        position: absolute;
        top: -50px;
        right: -4px;
        // background-image: url(../../../public/assets/img/data-arrow-up.png);
        svg {
            width: 20px;
            color: #000;
        }
    }
    .collapsed {
        .cart-icon-2 {
            display: none;
        }
    }
    .cart-count {
        position: absolute;
        right: 12px;
        top: 18px;
        width: 15px;
        height: 15px;
        /* background: #95cc47; */
        color: #212529;
        border-radius: 20px;
        text-align: center;
        font-size: 15px;
        line-height: 14px;
    }

.open-cart {
    position: absolute;
    top: -36px;
    right: 0px;
    i{
     font-size: 26px;
     cursor: pointer;
    }
}
.cart-icon-skeleton {
        width: 50px;
        padding: 15px 15px 15px 15px;
        position: absolute;
        top: -50px;
        right: -4px;
}
}


.cart-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.no-cart{
    padding: 10px;
    text-align: center;
    .item-empty-area__text {
        padding-top: 20px;
        font-size: 20px;
    }
    i{
        font-size: 20px;   
    }


}
.cart-section {
    tr {
        border-bottom: 1px solid #4f555a;
    }
    td.product-thumbnail {
        width: 65px !important;
        float: left;
        img {
            margin-right: 5px;
        }
    }
    td.product-quantity {
        width: 450px;
        .product-detail {
            position: relative;
            padding-left: 10px;
            .line-height-md {
                line-height: 16px;
                padding-right: 20px;
                a {
                    color: #333;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 500;
                }
            }
            p {
                span {
                    font-weight: 500;
                    color: #333;
                    font-size: 12px !important;
                }
                span.old {
                    text-decoration: line-through;
                    margin-right: 10px;
                    color: #8e8e8e;
                    font-size: 12px !important;
                }
            }
            .cart-plus-minus {
               //  display: inline-block;
                display: flex;
                height: 30px;
               //  padding: 0;
               //  position: relative;
               //  width: 80px;
                .qtybutton {
                    color: #333;
                    cursor: pointer;
                    float: inherit;
                    background: none;
                    border: none;
                    font-size: 16px;
                    margin: 0;
                    position: absolute;
                    transition: all 0.3s ease 0s;
                    width: 20px;
                    text-align: center;
                    font-weight: bold;
                }
                .dec.qtybutton {
                    height: 30px;
                    left: 0;
                    top: 0;
                    font-weight: bold;
                }
                .inc.qtybutton {
                    height: 30px;
                    right: 0;
                    top: 0;
                    font-weight: bold;
                }
                input.cart-plus-minus-box {
                    color: #333;
                    float: left;
                    font-size: 14px;
                    height: 30px;
                    margin: 0;
                    width: 80px;
                    background: transparent none repeat scroll 0 0;
                    border: none !important;
                    padding: 0;
                    text-align: center;
                }
            }
            .close-icon {
                position: absolute;
                top: -5px;
                right: 0px;
                background: transparent;
                border: none;
                font-size: 16px;
            }
        }
    }
}





.form-control{
    font-size: 14px;
}

.cursor-pointer {
    cursor: pointer;
}





.required {
    color: red;
}
#cardLogo {
    position: absolute;
    z-index: 9;
    left: 8px;
    font-size: 28px;
    top: -3px;
}
.form-control{
    height: calc(1.5em + 0.75rem + 2px);
}
#spreedly-number {
    position: relative;
}
.card-img{
    position: relative;
}
::-webkit-input-placeholder { /* Edge */
    font-size: 14px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 14px;
  }
  
  ::placeholder {
    font-size: 14px;
  }
  .pb-35 {
    padding-bottom: 35px;
}

.pt-35 {
    padding-top: 35px;
}
.bg-gray-3 {
    background-color: #f7f7f7;
}
 
.pb-100 {
    padding-bottom: 100px;
}

.pt-95 {
    padding-top: 25px;
}
  .your-order-area{


.inner-section-product {
    padding: 50px 20px;
}
.product-section {
    background-color: #f3f3f3;
    td{
        font-size: 15px;
    }
}
.price-tag {
    font-size: 20px;
    font-weight: bold;
}
.head-tag-2 {
    color: green;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
}
.inner-box {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-group {
    margin-bottom: 1rem;
}
.inner-box.address h4 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: normal;
}
.item-empty-area__text a {
    margin-top: 30px;
    font-weight: 500;
    display: inline-block;
    background-color: #363f4d;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 30px;
    transition: 0.3s;
    text-decoration: none;
}
}
#full_name {
    text-transform: capitalize;
}
.mobile-view{
    display: none
}
.full-address {
   margin-bottom: -20px;
}
.address-text {
   position: relative; 
   left: 20px;
   bottom: 25px;
   display: block;
   width: 95%;
}
.billing-address-text {
   position: relative;
    left: 7px;
    bottom: 1px;
}



@media only screen and (max-width: 991px) {

    .mobile-view {
        display: block
    }

         
    .mobile-view .cart-title {
        display: none
    } 
    .mobile-view .cart-section {
        display: none
    }  
    .mobile-view .ii {
        border-top: 1px solid #ccc;
        padding-top: 10px;
    }
    
    .desktop-view{

        .ii{
            display: none;
        }    
        }
  }